.dropdown {
  min-width: 20rem;
  padding: .5rem 0;
  position: absolute;
  right: .75rem;
  top: 4rem;

  .dropdown-list {
    width: 100%;
  }

  .dropdown-header {
    padding: 0 1rem;
    text-align: center;
  }

  .dropdown-item {
    font-size: 1rem;
    font-weight: $weight-semibold;
    padding: 0 1rem;
    text-align: left;

    a {
      color: $black;
      display: block;
      padding: .5rem 1rem;
      width: 100%;
    }

    a:hover {
      background-color: $mern-primary;
      color: $white;
      cursor: pointer;
    }
  }

  .dropdown-separator {
    margin: .5rem 0;
  }
}

.dropdown-caret {
  border: 4px solid $white;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-left: .25rem;
}




.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}

.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #63bcfd;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
}

.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  // position: absolute;
  // z-index:999;
}

.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}