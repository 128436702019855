.todo-page {
  .todo-section {
    background-color: $whitesmoke;
  }

  .space-right {
    margin-right: .75rem;
  }

  .title.is-1 {
    color: $dark-grey;
    margin-bottom: 20px;
  }

  .icon {
    vertical-align: middle;

    .fa {
      text-align: left;
      width: 21px;
    }

    .fa-pencil-alt,
    .fa-save, .fa-check-circle {
      color: $green;
    }

    .fa-ban,
    .fa-trash-alt ,.fa-times{
      color: $red;
    }
    .fa-ellipsis-h{
      color : $grey;
    }
    .fa-exclamation-circle{
      color: $yellow;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .confirm-modal {
    .modal-content {
      max-width: 400px;
    }
  }
}

.filter{
  .box {
    box-shadow:none;
  }
}
