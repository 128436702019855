table {
  border-spacing: 0;
  //border: 1px solid grey;

  tr {
    //border-bottom: 1px solid grey;
    border-top: 2px grey;

    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }


}

.filter-box {
  box-shadow: none;
}

.checkbox-grid{

  li {
    display:inline-block;
    //display: block;
    //float: left;
    width: 25%;

  }
}

.tag-grid{

  li {
    display:inline-block;
    //display: block;
    padding-right: 5px;
    float: left;

  }
}

